/**
 * Client Side - Search Filter Helpers
 */
import { Facet, ProductSortEnum } from '../../__generated__/graphql-client-types';
import { RANGE_FACET_VALUE_SEPARATOR } from '../../constants/search';
import { UseSearchResultsPayload } from '../../hooks/apollo/search/search.hooks';
import { FacetWithSource } from '../../types/search.types';

export type SearchState = { internalId: number; uniqueId: number } & Pick<
	UseSearchResultsPayload,
	'page' | 'pageSize' | 'sortBy' | 'facets' | 'isNonstock'
>;

const STOCK_STATUS_FACET = 'status_s';

/**
 * Resolve updated list of facets for new facet addition based on the current list of facets.
 */
const resolveFacetsForFacetAddition = (facet: Facet, facets: Facet[]): Facet[] => {
	const isRangeFacet = facet.value.includes(RANGE_FACET_VALUE_SEPARATOR);
	if (isRangeFacet) {
		const { lowRange, highRange } = getRangeFacetData(facet);
		return [...facets.filter((currentFacet) => currentFacet.id !== facet.id), lowRange, highRange];
	} else if (!existsSameFacetIn(facet, facets)) {
		return [...facets, facet];
	}
	return [...facets];
};

/**
 * Resolve updated list of facets for a facet deletion based on the current list of facets.
 */
const resolveFacetsForFacetDeletion = (facet: Facet, facets: Facet[]): Facet[] => {
	const isRangeFacet = facet.value.includes(RANGE_FACET_VALUE_SEPARATOR);
	if (isRangeFacet) {
		const { lowRange, highRange } = getRangeFacetData(facet);
		return facets.filter((currentFacet) => !isSameFacet(lowRange, currentFacet) && !isSameFacet(highRange, currentFacet));
	}
	return facets.filter((currentFacet) => !isSameFacet(facet, currentFacet));
};

/**
 * Returns true if a given facet exists in the existingFacets list using `isSameFacet` matching strategy, false otherwise.
 */
const existsSameFacetIn = (given: Facet, existingFacets: Facet[]) =>
	existingFacets.some((currentFacet) => isSameFacet(given, currentFacet));

/**
 * Filter facets out of a given list using by a list of facet ids.
 */
const excludeFacetsByIds = (facets: Facet[], ids: string[] = []): Facet[] =>
	facets.filter((currentFacet) => !ids.includes(currentFacet.id));

/**
 * Given a current page number, pageSize, and new pageSize we are changing to, calculate a new page number such that the first item of the
 * current page is shown in the new page.
 *
 * Index of first item on current page: pageSize * (page - 1)
 * Index of first item on new page: newPageSize * (newPage - 1)
 * To make this the same item, set these two expressions equal and solve for newPage:
 * newPageSize * (newPage - 1) = pageSize * (page - 1)
 * newPage = (pageSize * (page - 1)) / newPageSize + 1
 *
 * Page numbers must be integers, so we take the floor of the result, which may shift the old first item toward the middle of the new page.
 */
export const getNewPageForPageSizeChange = ({ page, pageSize }: Pick<SearchState, 'page' | 'pageSize'>, newPageSize: number): number =>
	Math.floor((pageSize * (page - 1)) / newPageSize + 1);

/**
 * Splits and returns 2 range-based facet data from input's facet value in the form of lowRange and highRange.
 */
export const getRangeFacetData = (rangeFacet: Facet): Record<'lowRange' | 'highRange', Facet> => {
	const [lowValue, highValue] = rangeFacet.value.split(RANGE_FACET_VALUE_SEPARATOR);
	return {
		lowRange: { id: rangeFacet.id, value: lowValue, rangeBound: 'LOW' },
		highRange: { id: rangeFacet.id, value: highValue, rangeBound: 'HIGH' }
	};
};

/**
 * Comparison strategy between 2 facets by:
 * 1) property id between given and existing
 * 2) OR given.sourceFacetId with existing id
 * 3) OR existing.sourceFacetId with given id
 */
export const facetIdMatch = (given: FacetWithSource, existing: FacetWithSource): boolean =>
	existing.id === given.id || existing.id === given.sourceFacetId || existing.sourceFacetId === given.id;

/**
 * Comparison strategy between 2 facets by:
 * 1) property id between given and existing
 * 2) OR given.sourceFacetId with existing id
 * 3) OR existing.sourceFacetId with given id
 * 4) AND values between given and existing.
 */
export const isSameFacet = (given: FacetWithSource, existing: FacetWithSource): boolean => {
	const idMatch = facetIdMatch(given, existing);
	return idMatch && existing.value.toLowerCase() === given.value.toLowerCase();
};

/**
 * Adds a single facet for the current searchState and using an update strategy.
 */
export const addFacet = (current: SearchState, update: (newState: SearchState) => void, facet: Facet) => {
	update({ ...current, page: 1, facets: resolveFacetsForFacetAddition(facet, current.facets) });
};

/**
 * Add multiple facets for the current searchState and using an update strategy.
 */
export const addFacets = (current: SearchState, update: (newState: SearchState) => void, facets?: Facet[]) => {
	if (facets && facets.length > 0) {
		const updatedFacets = facets.reduce((memo, facet) => resolveFacetsForFacetAddition(facet, memo), current.facets);
		update({ ...current, page: 1, facets: updatedFacets });
	}
};

/**
 * Removes a single facet for the current searchState and using an update strategy.
 */
export const removeFacet = (current: SearchState, update: (newState: SearchState) => void, facet: FacetWithSource) => {
	update({ ...current, page: 1, facets: resolveFacetsForFacetDeletion(facet, current.facets) });
};

/**
 * Removes multiple facets
 */
export const removeFacets = (current: SearchState, update: (newState: SearchState) => void, facets?: FacetWithSource[]) => {
	if (facets && facets.length > 0) {
		const updatedFacets = facets.reduce((memo, facet) => resolveFacetsForFacetDeletion(facet, memo), current.facets);
		update({ ...current, page: 1, facets: updatedFacets });
	}
};

/**
 * Clear and add facets
 */
export const clearAndAddFacets = (current: SearchState, update: (newState: SearchState) => void, facets?: Facet[]) => {
	addFacets({ ...current, page: 1, facets: [] }, update, facets);
};

/**
 * Clear all facets
 */
export const clearFacets = (current: SearchState, update: (newState: SearchState) => void) => update({ ...current, page: 1, facets: [] });

/**
 * Stores sort by strategy
 */
export const setSortBy = (current: SearchState, update: (newState: SearchState) => void, newSortBy: ProductSortEnum) =>
	update({ ...current, page: 1, sortBy: newSortBy });

/**
 * Stores current page
 */
export const setPage = (current: SearchState, update: (newState: SearchState) => void, newPage: number) =>
	update({ ...current, page: newPage });

/**
 * Stores current page size
 */
export const setPageSize = (current: SearchState, update: (newState: SearchState) => void, newPageSize: number) =>
	update({ ...current, pageSize: newPageSize, page: getNewPageForPageSizeChange(current, newPageSize) });

/**
 * Stores Non Stock Filter
 */
export const setNonstock = (current: SearchState, update: (newState: SearchState) => void, newNonstock: boolean) =>
	update({
		...current,
		isNonstock: newNonstock,
		facets: newNonstock ? current.facets : excludeFacetsByIds(current.facets, [STOCK_STATUS_FACET])
	});
